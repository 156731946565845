import * as React from "react"
import { SEO } from "../../components/seo"
import Layout from '../../components/vi/layout';
import ScrollAnimation from 'react-animate-on-scroll';
// import { StaticImage } from "gatsby-plugin-image"
import Gallery from '../../components/gallery';

const GalleryPage = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="h-72 bg-gradient-to-tl from-primary-dark to-primary-light relative after:content-[''] after:block after:bg-mask after:bg-['100%'] after:bg-bottom after:bg-no-repeat after:absolute after:inset-x-0 after:-bottom-1 after:w-full after:h-36 after:z-10">
        <div className="absolute w-full text-center inset-x-0 top-1/2 -translate-y-1/2 z-10 lg:p-20">
          <h1 className="animate__animated animate__fadeInDown animate__slower text-5xl text-white mb-4">Hình ảnh</h1>
        </div>
      </section>
      <section className="container mt-14 mx-auto px-4 lg:px-36 columns-2 md:columns-4">

        <ScrollAnimation animateIn="animate__fadeIn">
          <Gallery lang="en" />
        </ScrollAnimation>
       
        {/* <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-01.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-02.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-03.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-04.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-05.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-06.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-07.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-08.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-09.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-10.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-11.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-12.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-13.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-14.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-15.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-16.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-17.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn"><StaticImage src="../images/gallery/gallery-18.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" /></ScrollAnimation> */}

      </section>
    </Layout>
  )
}

export default GalleryPage

export const Head = ({location}) => <SEO title="Hình ảnh" pathname={location.pathname} />